import React from 'react';
import styled from 'styled-components';

const FAQ = () => {
  return (
    <FAQSection>
      <FAQTitle>CÂU HỎI THƯỜNG GẶP</FAQTitle>
      <FAQItem>
        <FAQQuestion>Giày có bảo hành không?</FAQQuestion>
        <FAQAnswer>Có, tất cả các sản phẩm đều có bảo hành chính hãng 1 năm.</FAQAnswer>
      </FAQItem>
      <FAQItem>
        <FAQQuestion>Tôi có thể giặt giày Nike bằng máy giặt không?</FAQQuestion>
        <FAQAnswer>Không nên giặt giày Nike bằng máy giặt vì điều này có thể làm hỏng cấu trúc và chất liệu của giày. Thay vào đó, hãy sử dụng bàn chải mềm và xà phòng nhẹ để làm sạch.</FAQAnswer>
      </FAQItem>
      <FAQItem>
        <FAQQuestion>Làm thế nào để đổi trả sản phẩm?</FAQQuestion>
        <FAQAnswer>Bạn có thể đổi trả trong vòng 30 ngày nếu sản phẩm không vừa ý.</FAQAnswer>
      </FAQItem>
      <FAQItem>
        <FAQQuestion>Giày Nike có phù hợp cho chạy bộ không?</FAQQuestion>
        <FAQAnswer>Nike có nhiều dòng giày được thiết kế riêng cho chạy bộ như Nike Air Zoom, Nike React, và Nike Free. Những dòng này có đệm tốt và thiết kế linh hoạt để hỗ trợ vận động và giảm chấn động khi chạy.</FAQAnswer>
      </FAQItem>
    </FAQSection>
  );
};

const FAQSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffffff;
  padding:50px 0;
  @media (max-width: 991px) {
    padding:10px 2%;
  }
`;

const FAQTitle = styled.h2`
  color: #00a1ff;
  font: 700 40px Quicksand, sans-serif;
  text-align:center;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 30px;
  }
`;

const FAQItem = styled.div`
  border-radius: 30px;
  background-color: #00a1ff;
  width: 1140px;
  max-width: 100%;
  margin-bottom:25px;
  padding: 12px 66px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const FAQQuestion = styled.h3`
  color: #ffffff;
  font: 700 26px Quicksand, sans-serif;
  margin-bottom: 10px;
`;

const FAQAnswer = styled.p`
  color: #fff;
  font: 500 18px Quicksand, sans-serif;
`;

export default FAQ;