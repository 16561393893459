import React from 'react';
import styled from 'styled-components';
import FeatureItem from './FeatureItem';

const featuresData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/752ef1222a1d1ab0cb102cd41265cfdd5534b18754a5f533f0798ced58c0f83d?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Công nghệ tiên tiến",
    description: "Cushlon và Air Zoom: Giúp tối ưu hóa lực đệm, tạo cảm giác thoải mái khi di chuyển, bảo vệ đôi chân khỏi chấn thương.\n\nFlyknit: Cấu trúc sợi đan nhẹ, bền bỉ, tạo sự thoáng khí tối ưu và ôm sát chân.\n\nReact Foam: Công nghệ đế giúp hấp thụ chấn động và tăng cường độ đàn hồi, mang lại cảm giác mềm mại và hỗ trợ tốt."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b0deebb2e950b51368ac126d2e108da053da3bae23970d6cd26f462999988df6?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Thiết kế hiện đại và đa dạng",
    description: "Nike nổi bật với các thiết kế thời thượng, hợp xu hướng và đa dạng phong cách từ thể thao, đường phố đến thời trang cao cấp.\n\nNhiều lựa chọn màu sắc và mẫu mã, phù hợp với mọi lứa tuổi và sở thích cá nhân."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3b95d087a9390403e9704401f1705d4a25b14564a6ed3564220a95764903f764?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Độ bền cao",
    description: "Sản phẩm được làm từ chất liệu cao cấp, có khả năng chịu mài mòn và bền bỉ trong thời gian dài.\n\nĐế giày cao su chất lượng giúp tăng độ bám dính và chống trơn trượt trên mọi bề mặt."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/752ef1222a1d1ab0cb102cd41265cfdd5534b18754a5f533f0798ced58c0f83d?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    title: "Phù hợp với nhiều hoạt động",
    description: "Giày Nike đáp ứng nhu cầu đa dạng, từ chạy bộ, tập luyện trong phòng gym, đến các môn thể thao như bóng rổ, bóng đá.\n\nDòng giày Lifestyle cũng được ưa chuộng cho những hoạt động hàng ngày và thời trang."
  },
  {
    icon: "https://ik.imagekit.io/s7qfxbeum/Group%201321316976%20(1).png?updatedAt=1729018814555",
    title: "Cảm giác thoải mái tuyệt đối",
    description: "Nike luôn chú trọng vào cảm giác của người mang, nhờ các công nghệ giảm chấn và thiết kế hỗ trợ bàn chân trong suốt quá trình vận động.\n\nForm giày ôm chân nhưng vẫn giữ được sự linh hoạt, giúp người mang cảm thấy nhẹ nhàng, thoải mái trong mọi hoạt động."
  },
  {
    icon: "https://ik.imagekit.io/s7qfxbeum/Group%201321316976%20(5).png?updatedAt=1729019212466",
    title: "Hiệu suất vượt trội",
    description: "Được các vận động viên chuyên nghiệp trên toàn thế giới tin dùng nhờ khả năng hỗ trợ hiệu suất thể thao vượt trội.\n\nGiúp người dùng đạt được kết quả tối ưu trong các buổi tập luyện và thi đấu."
  },

];

const Features = () => {
  return (
    <FeaturesSection>
      <FeaturesTitle>TÍNH NĂNG VƯỢT TRỘI CỦA NIKE</FeaturesTitle>
      <FeaturesList>
        {featuresData.map((feature, index) => (
          <FeatureItem key={index} {...feature} />
        ))}
      </FeaturesList>
    </FeaturesSection>
  );
};

const FeaturesSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffffff;
  padding:50px 0;
  margin-bottom:1rem;
  @media (max-width: 991px) {
    padding:10px 2%;
  }
`;

const FeaturesTitle = styled.h2`
  color: #00a1ff;
  text-align:center;
  font: 700 40px Quicksand, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 30px;
  }
`;

const FeaturesList = styled.div`
  display: flex;
  margin-top: 62px;
  width: 100%;
  max-width: 1538px;
  gap: 40px 100px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 15px;
    gap: 20px 100px;
  }
`;

export default Features;