import React from 'react';
import styled from 'styled-components';
import ProductItem from './ProductItem';

const productsData = [
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/3947ba90a5f644ad6dbc39a759d255e94921005ce7563992109e80d51ba4fb54?placeholderIfAbsent=true&apiKey=2413670ec67344988842962f2a60955b",
    name: "Giày NIKE AIR FORCE 1 LOW – WHITE COOL GREY NAVY BLUE ORANGE",
    price: "950 000 VNĐ",
    originalPrice: "1 300 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-nike-air-force-1-low-white-cool-grey-navy-blue-orange/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/07/Giay-Nam-Nike-AIR-ZOOM-PEGASUS-41-Blue.jpg.webp",
    name: "Giày Nam Nike AIR ZOOM PEGASUS 41- Blue",
    price: "130 000 VNĐ",
    originalPrice: "1 800 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-nam-nike-air-zoom-pegasus-41-blue-hf0398-400/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/09/Giay-NIKE-AIR-FORCE-1-LOW-ALL-WHITE.jpg",
    name: "Giày NIKE AIR FORCE 1 LOW – ALL WHITE",
    price: "850 000 VNĐ",
    originalPrice: "1 100 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-nike-air-force-1-low-all-white/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/07/NIKE-AIR-ZOOM-PEGASUS-41-7-1.jpg.webp",
    name: "Giày Nam Nike AIR ZOOM PEGASUS 41 – White Black Orange",
    price: "1 300 000 VNĐ",
    originalPrice: "1 800 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-nam-nike-air-zoom-pegasus-41-white-black-orange/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/07/Giay-Nam-Nike-AIR-ZOOM-PEGASUS-41-White-9.jpg.webp",
    name: "Giày Nam Nike AIR ZOOM PEGASUS 41- White",
    price: "1 300 000 VNĐ",
    originalPrice: "1 750 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-nam-nike-air-zoom-pegasus-41-white/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/08/Air-Zoom-Pegasus-41-Mint-Yellow-6.jpg.webp",
    name: "Giày Air Zoom Pegasus 41 – Mint Yellow",
    price: "1 230 000 VNĐ",
    originalPrice: "1 750 000 VNĐ",
    link:"https://giaygiare.store/san-pham/giay-air-zoom-pegasus-41-mint-yellow/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/08/Air-Zoom-Pegasus-41-Black-Mint-White.jpg.webp",
    name: "Giày Air Zoom Pegasus 41 – Black Mint White",
    price: "1 230 000 VNĐ",
    originalPrice: "1 750 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-air-zoom-pegasus-41-black-mint-white/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/09/Giay-NIKE-AIR-FORCE-1-LOW-07-FARMERS-MARKET-DESIGNED.jpg",
    name: "Giày NIKE AIR FORCE 1 LOW 07 – FARMER’S MARKET DESIGNED",
    price: "950 000 VNĐ",
    originalPrice: "1 300 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-nike-air-force-1-low-07-farmers-market-designed/"
  },
  {
    image: "https://giaygiare.store/wp-content/uploads/2024/07/NIKE-AIR-ZOOM-PEGASUS-41-ALL-BLACK.jpg.webp",
    name: "Giày NIKE AIR ZOOM PEGASUS 41- ALL BLACK",
    price: "1 230 000 VNĐ",
    originalPrice: "1 750 000 VNĐ",
    link: "https://giaygiare.store/san-pham/giay-nike-air-zoom-pegasus-41-all-black/"
  }
];

const ProductList = () => {
  return (
    <ProductSection>
      <ProductTitle>SẢN PHẨM NỔI BẬT CỦA NIKE</ProductTitle>
      <ProductGrid>
        {productsData.map((product, index) => (
          <ProductItem key={index} {...product} />
        ))}
      </ProductGrid>
      <a className="buy-button" target="_blank" href="https://giaygiare.store/danh-muc-san-pham/nike/">Xem thêm</a>
    </ProductSection>
  );
};

const ProductSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffffff;
  padding:50px 0;
  @media (max-width: 991px) {
    padding:10px 2%;
  }

  .buy-button {
    background-color: #000000;
    margin-top:2rem;
    color: #ffffff;
    border: none;
    padding: 15px 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff6347;
      color: white;
    }

    a{
      color: #ff4500;
    }
  }
`;

const ProductTitle = styled.h2`
  color: #00a1ff;
  font: 700 40px Quicksand, sans-serif;
  text-align:center;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 30px;
  }
`;

const ProductGrid = styled.div`
  margin-top: 56px;
  width: 1280px;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media (max-width: 991px) {
    margin-top: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export default ProductList;