import React from 'react';
import styled from 'styled-components';

const FeatureItem = ({ icon, title, description }) => {
  return (
    <FeatureWrapper>
      <FeatureIcon src={icon} alt={title} />
      <FeatureContent>
        <FeatureTitle>{title}</FeatureTitle>
        <FeatureDescription>{description}</FeatureDescription>
      </FeatureContent>
    </FeatureWrapper>
  );
};

const FeatureWrapper = styled.article`
  flex-grow: 1;
  flex-basis: calc(50% - 50px);
  display: flex;
  gap: 30px;
  @media (max-width: 991px) {
    flex-basis: 100%;
    flex-direction: column;
    gap: 20px;
  }
`;

const FeatureIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeatureTitle = styled.h3`
  color: #000;
  font: 700 30px Quicksand, sans-serif;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    font-size: 20px;
  }
`;

const FeatureDescription = styled.p`
  color: #000;
  font: 500 20px Quicksand, sans-serif;
  white-space: pre-line;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default FeatureItem;