import React from 'react';
import styled from 'styled-components';
import Aboutus from './components/Aboutus';
import Bannercta from './components/Bannercta';
import Ctabuy from './components/Ctabuy';
import CustomerFeedback from './components/CustomerFeedback';
import FAQ from './components/FAQ';
import Features from './components/Features';
import ProductList from './components/ProductList';

// Styled components
const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background:#ffffff;
`;


const BoxHome = styled.div`
  background:#ffffff;
`;

// React component
const Home = () => {
  return (
    <BoxHome>
      <Aboutus/>
      <Features/>
      <ProductList/>
      <Bannercta/>
      <CustomerFeedback/>
      <FAQ/>
      <Ctabuy/>
      <Container>

      </Container>

      {/* Footer */}

    </BoxHome>
  );
};

export default Home;
