import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const AboutSection = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #000000;
    .group-ctab{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        gap:15px;
        align-items: center;

        p{
            margin-bottom:0;
        }
    }
  @media (max-width: 991px) {
    padding:10px 0;
    min-height: 100px;
  }
`;

const SaleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
  height: 100%;
  gap:20px;
  text-align: center;
  font-family: Arial, sans-serif;

  h1 {
    font-size: 1.4rem;
    margin-bottom:0;

    a{
      color:#00a1ff;
      /* display:block; */
      @media (max-width: 700px) {
        display:none;
      }
    }
  }

  .timer {
    font-size: 1.5rem;
  }

  .grouptimer{
    display: flex;
    align-items: center;
    gap:10px;
  }

  .buy-button {
    background-color: #fff;
    color: #ff4500;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff6347;
      color: white;
    }

    a{
      color: #ff4500;
    }
  }

  @media (max-width: 991px) {
    flex-wrap:wrap;
    gap:5px;
  }
`;

const CountdownTimer = ({ hours, minutes, seconds }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  });

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const { hours, minutes, seconds } = timeLeft;

      if (seconds > 0) {
        setTimeLeft({ hours, minutes, seconds: seconds - 1 });
      } else if (minutes > 0) {
        setTimeLeft({ hours, minutes: minutes - 1, seconds: 59 });
      } else if (hours > 0) {
        setTimeLeft({ hours: hours - 1, minutes: 59, seconds: 59 });
      } else {
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLeft]);

  const { hours: h, minutes: m, seconds: s } = timeLeft;
  return (
    <div className="timer">
      {h < 10 ? `0${h}` : h}h : {m < 10 ? `0${m}` : m}p : {s < 10 ? `0${s}` : s}s
    </div>
  );
};

const Ctabuy = () => {
  return (
    <AboutSection>
    <SaleWrapper>
      <h1>SIÊU SALE GIẢM GIÁ 50%<a target="_blank" href="https://giaygiare.store/">GIAYGIARE.STORE</a></h1>
      <div className="grouptimer">
      <CountdownTimer hours={9} minutes={21} seconds={16} />
      <button className="buy-button"> <a target="_blank" href="https://giaygiare.store/danh-muc-san-pham/nike/"> MUA NGAY</a></button>
      </div>

    </SaleWrapper>
    </AboutSection>
  );
};

export default Ctabuy;



